<template>
  <div class="controls">
	<fa-icon @click="$emit('refresh')" icon="arrows-rotate"/><br/>
	<fa-icon @click="$emit('wigle')" icon="address-book"/>
  </div>
</template>

<script>
export default {
  name: 'AppControls',
  props: {
    status: String,
    tooltip: Array
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.controls {
	position:absolute;
	display:block;
	top: 5px;
	right: 40px;
	background: darkgray;
	color: white;
	margin: 10px;
	padding: 10px;
	font-size: 20px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
