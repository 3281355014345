<template>
  <div ref="map-root"
       style="left:0; top: 0; width: 100%; height: 100%; content: '';">
  </div>
</template>

<script>
  import View from 'ol/View'
  import Map from 'ol/Map'
  import TileLayer from 'ol/layer/Tile'
  import OSM from 'ol/source/OSM'
  import VectorLayer from 'ol/layer/Vector'
  import VectorSource from 'ol/source/Vector'
  import Circle from 'ol/geom/Circle'
  import {fromLonLat} from 'ol/proj'
  import Feature from 'ol/Feature'
  import LayerStyle from 'ol/style/Style'
  //import CircleStyle from 'ol/style/Circle'
  import FillStyle from 'ol/style/Fill'
  import StrokeStyle from 'ol/style/Stroke'

  import 'ol/ol.css'

  export default {
    name: 'MapContainer',
    components: {},
    props: {
      device: Array,
      features: Array,
    },
    data: () => ({
      olMap: null,
      vectorLayer: null,
      selectedFeature: null,
      styleCache: []
    }),
    mounted() {
	const style = new LayerStyle({
		fill: new FillStyle({color: 'rgba(255,127,255,0.5)'}),
		stroke: new StrokeStyle({color: 'rgba(255,7,255,1.0)'})
	});
      this.vectorLayer = new VectorLayer({
        source: new VectorSource({
          projection: 'EPSG:4326',
          features: []
        }),
	style: style,
      })

      this.olMap = new Map({
        target: this.$refs['map-root'],
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          this.vectorLayer
        ],
        view: new View({
          zoom: 0,
          center: [0, 0],
          constrainResolution: true
        }),
      })

      this.olMap.on('pointermove', (event) => {
	const features = []
        this.olMap.forEachFeatureAtPixel(event.pixel, (feature) => {features.push(feature); return false;})
	var update = this.selectedFeature != null && this.selectedFeature.length != features.length
	if (this.selectedFeature != null) {
		for (let i in features) {
			var found = false
			var feat = features[i]
			for (let j in this.selectedFeature) {
				if (feat.values_.id == this.selectedFeature[j].values_.id) {
					found = true
					break
				}
			}	
			if (!found) {
				update = true
			}
		}
	}
        if (this.selectedFeature == null || update) {
          this.selectedFeature = features;
        }
      })
    },
    watch: {
      features(value) {
	this.addFeatures(value)
      },
      selectedFeature(value) {
        this.$emit('select', value)
      }
    },
    methods: {
      getStyle(colour) {
	for (let i in this.styleCache) {
		if (this.styleCache[i].fill_.color_ == colour) {
			return this.styleCache[i]
		}
	}
	const style = new LayerStyle({
		fill: new FillStyle({color: colour}),
		stroke: new StrokeStyle({color: 'rgba(255,127,255,0.75)'})
	});
	this.styleCache.push(style)
	return style
      },
      addFeatures(features) {
	//const layers = this.olMap.getLayers().getArray()
	if (features == undefined || features.length == 0) {
		return;
	}
        const view = this.olMap.getView()
        const source = this.vectorLayer.getSource()
	source.clear()

	var feats = []
	for (let fi in features) {
		const feature = features[fi]
		if (feature.latitude == 0.0 || feature.longitude == 0.0) {
			continue
		}
		const trans = fromLonLat([feature.longitude, feature.latitude])
		if (feature.radius == undefined) {
			feature.radius = 2
		}
		var point = new Circle(trans, feature.radius)
		const feat = new Feature({geometry: point, id: feature.id})
		feat.setStyle(this.getStyle(feature.color))
		feats.push(feat)
	}
	if (feats.length == 0) {
		return 0
	}

        source.addFeatures(feats)

	this.olMap.updateSize()
        view.fit(source.getExtent())
      }
    }
  }
</script>
<style scoped>
div {
	height: 100%;
	width: 100%;
	position: absolute;
}
</style>
