<template>
  <div class="overlay">
    <p v-for="ap in aps" :key="ap">
	<fa-icon icon="signal" class="icon half" /> <fa-icon v-if="locationIcon(ap).length > 0" @click="$emit('wigle', ap)" :icon="locationIcon(ap)" /> {{ap.ssid}}
    </p>
  </div>
</template>

<script>
export default {
  name: 'WifiOverlay',
  props: {
    aps: Object
  },
  methods: {
	locationIcon(ap) {
		if (ap.latitude > 0) {
			return "location-pin-lock"
		} else {
			return "location-pin"
		}
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
	margin-left: 5px;
	margin-right: 5px;
	position: relative;
}
.icon.half:after {
	content: "";
	position: absolute;
	background: black;
	width: 100%;
	height: 100%;
	left: 50%;
}
.overlay {
	position:absolute;
	display:block;
	bottom: 5px;
	left: 5px;
	background: black;
	color: white;
	margin: 10px;
	padding: 5px;
	font-size: 12px;
}
</style>
