<template>
<!-- login url
https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
-->
  <!-- Genuine UI -->
  <MapContainer :features="features" v-on:select="selected = $event" />
  <InfoOverlay :tooltip="tooltips" :battery_level="battery_level"/>
  <WifiOverlay :aps="wifi" @wigle="(ap) => select_wigle(ap)"  @select="(ap) => select_point(ap)" />
  <AppControls v-if="this.refreshing == false" @refresh="refreshing = true" @wigle="wigle_mode = !wigle_mode" />
  <FadingAlert :msg="alert" :duration="alertduration"/>

  <!-- Data sources -->
  <SaferPet
	:selected="selected"
	:refresh="refreshing"
	:wigle="wigle_mac"
	:only_wigle="wigle_mode"
	@refresh_complete="this.refreshing = false"
	@battery_level="battery_level = $event"
	@tooltips="pet_tooltips = $event"
	@wifi="pet_wifi = $event"
	@features="pet_features = $event" />
</template>

<script>
import AppControls from './components/AppControls.vue'
import InfoOverlay from './components/InfoOverlay.vue'
import FadingAlert from './components/FadingAlert.vue'
import WifiOverlay from './components/WifiOverlay.vue'
import MapContainer from './components/Map.vue'
import SaferPet from './components/SaferPet.vue'

export default {
  name: 'App',
  components: {
    AppControls,
    InfoOverlay,
    MapContainer,
    FadingAlert,
    SaferPet,
    WifiOverlay
  },
  computed: {
	tooltips() {
		return [...this.pet_tooltips]
	},
	wifi() {
		return {...this.pet_wifi}
	},
	features() {
		if (this.wigle_features.length > 0) {
			return this.wigle_features
		}
		return this.pet_features
	},
  },
  data() {
	return {
		battery_level: -1,
		pet_tooltips: [],
		pet_wifi: {},
		pet_features: [],
		wigle_features: [],
		port: 9054,
		selected: [],
		refreshing: false,
		alert: "",
		wigle_mac: "",
		wigle_mode: false,
		alertduration: 5000
	}
  },
  methods: {
	select_wigle(ap) {
		if (this.wigle_mac == ap.mac) {
			this.wigle_mac = ""
		} else {
			this.wigle_mac = ap.mac
		}
	},
	select_point(ap) {
		if (ap.latitude != 0.0) {
			console.log("Force zoom on this one")
			console.log(ap.latitude)
			console.log(ap.longitude)
		}
	}
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
