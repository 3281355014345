<template>
  <div :style="visible">{{msg}}</div>
</template>

<script>
export default {
  name: 'FadingAlert',
  props: {
    msg: String,
    duration: Number
  },
  data() {
  return {
      visible: "display: block;"
  }
  },
  watch: {
  msg() {
    this.visible = "display: block;"
    setTimeout(() => {
      this.visible = "display: hidden;"
    }, this.duration);
  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  
}
</style>
