import { createApp } from 'vue'
import App from './App.vue'

// Import icons and register component
import {library as fontlib} from '@fortawesome/fontawesome-svg-core'
import {
	faArrowsRotate,
	faSignal,
	faAddressBook,
	faMobileScreenButton,
	faExclamation,
	faLocationDot,
	faLocationPin,
	faLocationPinLock,
	faCarBattery,
	faBatteryEmpty,
	faBatteryQuarter,
	faBatteryHalf,
	faBatteryThreeQuarters,
	faBatteryFull
	} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
fontlib.add(faArrowsRotate)
fontlib.add(faSignal)
fontlib.add(faAddressBook)
fontlib.add(faMobileScreenButton)
fontlib.add(faExclamation)
fontlib.add(faLocationDot)
fontlib.add(faLocationPin)
fontlib.add(faLocationPinLock)
fontlib.add(faCarBattery)
fontlib.add(faBatteryEmpty)
fontlib.add(faBatteryQuarter)
fontlib.add(faBatteryHalf)
fontlib.add(faBatteryThreeQuarters)
fontlib.add(faBatteryFull)

const app = createApp(App)
app.component('fa-icon', FontAwesomeIcon)
app.mount('#app')
