<template>
  <div class="overlay">
    <div>Battery: {{ battery_level_s }}% <fa-icon :icon="battery_level_icon" :title="battery_level_s" class="icon"/></div>
    <div v-for="tip in tooltip" :key="tip">
	{{ tip }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoOverlay',
  props: {
    battery_level: Number,
    tooltip: Array
  },
  computed: {
	battery_level_s() {
		return ""+this.battery_level
	},
	battery_level_icon() {
		console.log('computed from ' + this.battery_level)
		if (this.battery_level > 75) {
			return "battery-full"
		} else if (this.battery_level > 50) {
			return "battery-three-quarters"
		} else if (this.battery_level > 25) {
			return "battery-half"
		} else if (this.battery_level > 10) {
			return "battery-quarter"
		} else if (this.battery_level > 0) {
			return "battery-empty"
		} else {
			return "car-battery"
		}
	}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon {
	vertical-align: text-bottom;
	width: 25px;
	height: 22px;
}
.overlay {
	position:absolute;
	display:block;
	top: 5px;
	left: 40px;
	background: black;
	color: white;
	margin: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 12px;
}
</style>
