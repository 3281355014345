<template>
	<div/>
</template>

<script>
import chroma from 'chroma-js'

export default {
  name: 'SaferPet',
  mounted() {
	this.do_refresh()
	this.get_all_wigle_mac()
	clearInterval(this.interval)
	this.interval = setInterval(this.do_refresh, 10000)
  },
  unmounted() {
	clearInterval(this.interval)
  },
  props: {
	refresh: Boolean,
	selected: Array,
	wigle: String
  },
  data() {
	return {
		loc: {},
		wigle_mac: {},
		wigle_loc: [],
		only_wigle: false,
		petname: 'Pluto',
		port: 9054,
		lastChanged: 0,
		edit: false,
		interval: null,
		refreshing: false,
		alert: "",
		alertduration: 5000
	}
  },
  watch: {
	refresh(v) {
		if (v === true) {
			this.request_location()
		}
	},
	wigle(v) {
		if (v != "") {
			this.get_wigle_locations(v)
		} else {
			this.wigle_loc = []
		}
	},
	tooltips(v) {
		this.$emit('tooltips', v)
	},
	features(v) {
		this.$emit('features', v)
	}
  },
  methods: {
	fetch(path, timeout) {
		if (timeout == undefined) {
			timeout = 8000
		}
		console.log(`fetching ${path}`)
		const aborter = new AbortController()
		setTimeout(() => aborter.abort(), timeout)
		const head = {}
		head['Authorization'] = 'test'
		return fetch(`${this.url}${path}`, {signal: aborter.signal, headers: head})
	},
	request_location() {
		this.refreshing = true
		this.fetch('request-location', 45000).then(response => response.json()).then(data => {
			this.alert = data.code
		}).finally(() => {
			this.$emit('refresh_complete')
			setTimeout(() => this.do_refresh(), 2000)
		})
	},
	get_wigle_locations() {
		this.fetch('wigle-mac_'+this.wigle)
			.then(response => response.json())
			.then(data => {
				console.log(data)
				this.wigle_loc = data
			})
	},
	get_all_wigle_mac() {
		this.fetch('wigle-all')
			.then(response => response.json())
			.then(data => {
				this.wigle_mac = data
			})
	},
	do_refresh() {
		if (document.webkitHidden) {
			return
		}
		this.fetch('location')
			.then(response => response.json())
			.then(data => {
				if (this.loc != undefined) {
					if (this.loc.time == data.time && this.loc.last_req == data.last_req && this.loc.battery == data.battery) {
						return
					}
				}
				this.loc = data
				this.$emit('wifi', this.wifi)
				this.$emit('battery_level', this.loc.battery)
				//this.$emit('features', this.features)
				//this.$emit('tooltips', this.tooltips)
			})
			.catch(error => {
				console.log("Failed to retrieve location data " + error)
			})
	},
	rssi_to_pct(rssi) {
		rssi = rssi * -1
		// positive numbers now, 30 is the strongest rssi i can expect
		//rssi -= 30
		rssi = 100 - rssi
		// 70 is now 'highest possible signal strength' and 0 is now 'lowest possible signal strength'
		return rssi/60
	},
  },
  computed: {
	wifi() {
		if (this.loc != undefined) return this.loc.wifi
		return {}
	},
	tooltips() {
		var s = []
		if ('wifi' in this.loc) {
			var localoffset = new Date().getTimezoneOffset()*-1*60
			var date = new Date((this.loc.time+localoffset)*1000)
			s.push("Last collar reply: "+date.toLocaleString())
			var d = new Date((this.loc.last_req+localoffset)*1000).toLocaleString()
			if (this.loc.last_req == 0) {
				d = "unknown"
			}
			s.push("Last request to collar: "+d)
			if (this.loc.exception != "") {
				s.push("SERVER ERROR: " + this.loc.exception)
			}
			if (this.wigle != "") {
				if (this.wigle in this.wigle_mac) {
					const ap = this.wigle_mac[this.wigle]
					s.push(`Wigle Wifi AP selected: ${ap.ssid} ${ap.mac} ${ap.latitude} ${ap.longitude}`)
				}
				// if we have a 'true wifi' point for this in our current set
				if (this.wigle in this.loc.wifi) {
					const ap = this.loc.wifi[this.wigle]
					s.push(`Wigle WiFi AP true location: ${ap.latitude} ${ap.longitude}`)
				}
			}
			for (var i in this.selected) {
				const selectedMac = this.selected[i].values_.id
				var ap = this.loc.wifi[selectedMac]
				if (selectedMac[0] == 'z') {
					ap = this.wigle_loc[selectedMac.substr(1)]
					const pct = this.rssi_to_pct(ap.rssi)
					const t = new Date(ap.time)
					s.push(`${selectedMac.substr(1)} - ${t} @ ${ap.mac} (RSSI ${ap.rssi} ${pct*100}) ${ap.accuracy}`)
					continue
				}
				if (ap != undefined) {
					s.push(`${ap.ssid} @ ${ap.mac} (RSSI ${ap.rssi})`)
				}
			}
		} else {
			s.push("waiting for server...")
		}
		return s
	},
	features() {
		var features = []
		if ('wifi' in this.loc == false) return features;

		const col = chroma.scale(['red', 'yellow', 'green'])//.mode('lab')
		for (var i in this.wigle_loc) {
			const ap = this.wigle_loc[i];
			if (ap.accuracy > 10) {
				console.log("Inaccurate point")
				//continue
			}
			const pct = this.rssi_to_pct(ap.rssi)
			var rads = 0
			if (ap.accuracy < 5) {
				rads = 1
			} else if (ap.accuracy < 11) {
				rads = 0.75
			} else {
				continue
			}
			features.push({...ap, id: 'z'+i, color: col(pct), radius: rads})
		}

		if (this.wigle in this.wigle_mac) {
			features.push({...this.wigle_mac[this.wigle], id: '', color: 'rgba(0, 0, 255, 1)', radius: 1})
		}
		if (this.wigle in this.loc.wifi) {
			features.push({...this.loc.wifi[this.wigle], id: '', color: 'rgba(0, 0, 27, 1)', radius: 1})
		}
		if (features.length > 1) {
			return features
		}

		for (var mac in this.loc.wifi) {
			const ap = this.loc.wifi[mac];
			var color = 'rgba(0,0,0,0.5)'
			var radius = 10;
			if (ap.rssi > -85) {
				color = 'rgba(0, 255, 76, 0.5)'
			} else if (ap.rssi > -75) {
				color = 'rgba(255, 131, 0, 0.5)'
				radius = 20
			} else {
				color = 'rgba(255, 0, 0, 0.5)'
				radius = 30 
			}
			features.push({...ap, id: mac, color: color, radius: radius})
		}
		return features
	},
	url() {
		if (process.env.NODE_ENV == "development") {
			return `http://${window.location.hostname}:${this.port}/`
		} else {
			return `${window.location.origin}/api/`
		}
	}
  },
}
</script>

<style>
</style>
